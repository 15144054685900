@import '../partials';

.landing-banner {
  position: relative;

  &__boundary {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // padding-top: rem(111);
    z-index: 2;

    // Media query for wider screens
    @media (min-width: 1730px) {
     // width: calc(100vw - 300px);
    }

    // Media query for medium-width screens
    @media (max-width: 1729px) and (min-width: 1411px) {
      //width: calc(100vw - 110px);
    }

    // Media query for smaller screens
    @media (max-width: 1410px) {
      //width: calc(100vw - -200px);
    }
  }

  &__title {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: $font-weight-heavy;
    font-size: rem(64);
    padding: 0 $component-spacing;

    &-top {
      font-size: rem(16);
      letter-spacing: 0.6em;

      @include bp(sm) {
        font-size: rem(22);
      }

      @include bp(lg) {
        font-size: rem(24);
      }
    }

    &-center {
      font-size: rem(62);
      line-height: 0.9em;
      transform: translateY( .1em );

      @include bp(sm) {
        font-size: rem(103);
      }

      @include bp(lg) {
        font-size: rem(172);
      }
    }

    &-bottom {
      font-size: rem(40);
      font-style: italic;
      font-weight: $font-weight-normal;

      @include bp(sm) {
        font-size: rem(56);
      }

      @include bp(lg) {
        font-size: rem(72);
      }
    }

    span {
      display: block;
    }
  }

  &__button {
    @include box-button($white);

    pointer-events: all;
    margin-top: rem(45);

    @include bp(xs) {
      display: none;
    }
  }

  &__arrow {
    width: rem(26);
    height: rem(131);
    background-image: url('../DocumentDefault/Assets/arrow-home-hero.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto; // Centers horizontally by default
    display: block;
    top: 45rem;
    position: absolute;

    // For mobile (xxs)
    @include bp(xxs) {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 40rem;
      transform: none;
    }
  
    // For tablets (sm)
    @include bp(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 75rem;
      transform: none;
    }
    @include bp(lg) {
      top: 40rem;
    }
    // Custom breakpoint
    @media (max-width: 414px) {
      top: 38rem;
    }
  }

  &__image {
    position: relative;
    height: 100vh;
    min-height: 880px;
    overflow: hidden;

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &--single {
      .flickity-page-dots {
        pointer-events: none;
        visibility: hidden;
      }
    }

    .flickity-page-dots {
      pointer-events: all;
      bottom: rem(100);
      display: flex;
      align-items: center;
      justify-content: center;

      @include bp(md) {
        padding-top: rem(160);
        bottom: rem(80);
        flex-direction: column;
        width: rem(24);
        right: rem(24);
        height: 100%;
      }

      .dot {
        margin: 0 rem(12);
        width: rem(16);
        height: rem(16);
        opacity: 0.6;
        background-color: $white;
        transition-property: width, height, opacity;
        transition-duration: $fast-duration;

        @include bp(md) {
          margin: rem(12) 0;
        }

        &:hover {
          opacity: 1;
        }

        &.is-selected {
          opacity: 1;
        }
      }
    }
  }

  .flickity-viewport {
    height: 100% !important;
  }

  &__slide {
    height: 100%;
    width: 100%;

    &:before {
      @include overlay-gradient;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      content: " ";
      z-index: 1;
    }

    &.is-selected .landing-banner__slide-info {
      display: block;
    }
  }

  .status-blocks {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;

    .status-block {
      padding: rem(19) rem(24) rem(17) rem(24);
      color: $white;
      font-size: rem(18);
      text-transform: uppercase;

      &__label,
      .op-open,
      .op-closed {
        color: $white;
        font-weight: $font-weight-heavy;
      }

      &--calendar {
        background-color: $pink;

        @include bp(md) {
          min-width: rem(200);
        }
      }

      &--weather {
        background-color: $pink-light;

        div {
          position: relative;
        }

        &-icon {
          height: rem(41);
          margin-bottom: rem(-5);
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  &__slide-info {
    display: none;
    position: relative;
    z-index: 99999;
    width: 100%;
    height: 100%;
    top: 0;

    &__inner {
      max-width: $container-width;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 rem(15);

      @include bp(lg) {
        padding: 0 rem(20);
      }
    }
  }

  &__category {
    font-family: $gotham-font;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: rem(16);
    line-height: 19px;
    text-transform: uppercase;
    color: $green-conifer;
    margin-bottom: 21px;
  }

  &__event-title {
    color: $white;
    font-family: $gotham-font;
    font-style: italic;
    font-weight: $font-weight-regular;
    font-size: rem(64);
    line-height: 77px;
    text-transform: uppercase;
    max-width: 570px;
  }

  &__date-container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
    margin-top: 20px;
    padding-top: 35px;
    border-top: 1px solid $white;
    max-width: 570px;
  }

  &__date-left {
    color: $white;
    font-family: $gotham-font;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: rem(16);
    line-height: 19px;
    text-transform: uppercase;
  }

  &__date-right {
    color: $white;
    font-family: $gotham-font;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: rem(16);
    line-height: 19px;
    text-transform: uppercase;
  }

  &__cta-button {
    display: block;
    color: $white !important;
    border: 2px solid $white;
    font-family: $gotham-font;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: rem(12);
    line-height: 14px;
    text-transform: uppercase;
    padding: 10px 26px;
    width: fit-content;
    margin-top: 30px;
  }
}
